import styled from "styled-components";
import Colors from "../../themes/Colors";

const CardAverageCPMHolder = styled.div`
  .cartAudience {
    position: fixed;
    z-index: 996;
    top: 10px;
    right: 10px;
    height: auto;
    width: auto;
    line-height: 25px;
    background-color: ${Colors.primary};
    font-size: 16px;
    text-align: center;
    color: aliceblue;
    border-radius: 8px;
    box-shadow: rgb(0 0 0 / 16%) 0px 1px 4px;
    max-width: 11%;

    @media only screen and (max-width: 1200px) {
      position: absolute;
      width: 30%;
      top: 140px;
      margin-bottom: 10px;
    }
    @media only screen and (max-width: 500px) {
      position: absolute;
      width: 40%;
      top: 140px;
      margin-bottom: 10px;
    }
    @media only screen and (min-width: 1201px) and (max-width: 1400px) {
      width: 11%;
      .impacts-container {
        line-height: 20px;
      }
    }
    @media only screen and (min-width: 576px) {
      top: calc(585px + 70px);
      max-width: 11%;
    }
    @media only screen and (min-width: 1000px) {
      top: calc(510px + 12px);
      max-width: 11%;
    }
    @media only screen and (max-width: 575px) {
      margin-left: 10px;
      margin-top: 10px;
      width: 11%;
    }
    .buttonAudienceDetails {
      margin-top: 6px;
    }
  }
  .cartAudienceHidden {
    position: fixed;
    z-index: 2;
    top: calc(50% - 25px);
    right: -150px;
    height: 45px;
    width: 150px;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
    background-color: ${Colors.primary};
    font-size: 16px;
    font-weight: 600;
    @media only screen and (min-width: 768px) {
      top: calc(85px + 127px + 140px);
    }
    @media only screen and (min-width: 1000px) {
      top: calc(85px + 77px + 120px);
    }
  }
  .cartAudienceVideoUpload {
    position: fixed;
    z-index: 996;
    top: 10px;
    right: 10px;
    height: auto;
    min-width: 11%;
    line-height: 30px;
    background-color: ${Colors.primary};
    font-size: 16px;
    text-align: center;
    color: aliceblue;
    border-radius: 8px;
    margin-bottom: 10px;

    @media only screen and (max-width: 1200px) {
      position: absolute;
      margin: 10px;
      right: 0;
      width: fit-content;
      top: 110px !important;
      max-width: fit-content !important;
    }
    @media only screen and (min-width: 576px) {
      top: calc(5px + 300px + 70px);
    }
    @media only screen and (min-width: 1000px) {
      top: calc(49px + 170px + 12px);
      max-width: 11%;
    }
    @media only screen and (max-width: 1400px) {
      max-width: 11%;
      .impacts-container {
        line-height: 20px;
      }
    }
    .buttonAudienceDetails {
      margin-top: 6px;
    }
  }
  .impacts-container{
    display: flex;
    align-items: center;
  }
  .text-title {
    text-align: left;
    font-size: 20px;
    opacity: 0px;
    margin-bottom: 10px;
  }
  .content-card {
    display: flex;
  }
  .text-data{
    font-weight: 600;
    font-size: 18px;
  }
  .col-card{
    display: content;
    margin-right: 10px;
    margin-left: 10px;
  }
  .col-card-margin{
    margin-top: 10px;
    margin-left: 0px;
  }
  .line-divider{
    width: 100%;
    min-width: 80%;
    margin-top: 10px;
    margin-bottom: 5px;
  }
  .buttonAudienceDetails {
    background: none;
    margin-left: 10px;
    height: 22px;
    padding-left: 10px;
    padding-right: 10px;
    color: white;
  }
  .cartAudience {
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1)
  }
  .cartAudienceHidden {
    transition: all 0.3s cubic-bezier(1, 0.355, 0.045, 0.645)
  }
  .cartAudienceHidden .ant-row {
    visibility: hidden;
  }
  .cartAudience .anticon.aimIcon svg, .cartAudienceVideoUpload .anticon.aimIcon svg{
    width: 19px;
    height: 19px;
  }
  .cardAudienceInfo {
    font-size: 13px;
    margin-right: 5px
  }
  .cardAudienceTypeInfo {
    font-size: 12px
  }
  .cardAudienceIconInfo {
    margin: 10px 5px 5px 0px;
  }
  .cardAudienceIconInfoInfo {
    margin: 10px 5px 5px 5px;
  }
  .carAudienceAimIcon {
    margin-right: 5px;
    margin-left: 5px
  }
    .infoDate {
    margin-top: 10px;
    margin-bottom: 5px;
    font-size: 13px;
    font-weight: 400;
    line-height: 22px;
    text-align: left;
    color: #D7EAFD;
  }
  .content-card {
    display: flex;
    text-align: justify;
  }
  .cardGeneral {
    padding-left: 10px;
    padding-bottom: 5px;
    line-height: 30px;
    font-size: 16px;
    text-align: center;
    color: aliceblue;
  }
  .brandClientNameContainer {
    background-color: white;
    border-radius: 8px;
    padding: 2;
    margin-right: 10px;
    
    @media only screen and (max-width: 576px) {
      margin-right: 10px;
      padding: 8px;
    }
  }
  .brandClientName {
    margin: 0;
    color: ${Colors.primary};
    font-weight: 500;
  }
  .infoDate {
    margin-top: 10px;
    margin-bottom: 5px;
    font-size: 13px;
    font-weight: 400;
    line-height: 22px;
    text-align: left;
    color: #D7EAFD;
  }
  .seeDetailsBtn {
    background-color: #1890ff;
    color: white;
    padding: 4px 15px;
    font-size: 9px;
    height: auto;
    cursor: pointer;
    border: 1px solid white;
    border-radius: 4px;
    width: 100%;
    min-width: 70px;
    margin-top: 5px;
    margin-bottom: 5px;
    &:hover {
      background-color: #40a9ff;
      border-color: white;
    }
    &:focus {
      background-color: #1890ff;
      color: white;
      border-color: white;
    }
  }
}`;

export default CardAverageCPMHolder;